import styled from "styled-components"

export const SiteContainer = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
`

export const LogoContainer = styled.div`
  transition: 300ms all;
  z-index: 3;
  position: fixed;
  top: 1rem;
  left: 1rem;
  height: 70px;
  opacity: 0.5;
  
  :hover {
    opacity: 1;
  }
`

export const ExternalLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`

export const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 1rem 1rem;
  height: calc(100% - 100px);
`

export const Main = styled.main`
  padding-bottom: 2rem;
`
