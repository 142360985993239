import React from "react"
import * as Styled from "../styles/blogposts-rows.styles"

const RenderImage = (image, title) =>
  image ? (
    <Styled.Image fluid={image.fluid} alt={title} />
  ) : (
    <Styled.ImagePlaceholder />
  )

const RenderTags = post => {
  return post.referenceTags.map(tag => (
    <Styled.Tag key={tag.name}>{tag.name}</Styled.Tag>
  ))
}

const BlogpostsRows = ({ posts }) => {
  return (
    <Styled.Background>
      {posts.map(post => (
        <Styled.Link key={post.slug} to={`/blogpost/${post.slug}`}>
          <Styled.PostContainer key={post.id}>
            {RenderImage(post.image, post.title)}
            <Styled.Metadata>
              <Styled.Title>{post.title}</Styled.Title>

              <Styled.TagsContainer>{RenderTags(post)}</Styled.TagsContainer>

              <Styled.Description>
                {post.description.description}
              </Styled.Description>
              {/*<Styled.Description>*/}
              {/*  {post.body.childMarkdownRemark.excerpt}*/}
              {/*</Styled.Description>*/}
            </Styled.Metadata>
          </Styled.PostContainer>
        </Styled.Link>
      ))}
    </Styled.Background>
  )
}

export default BlogpostsRows
