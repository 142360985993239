import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as Styled from "../styles/blogpost.styles"
import { updateProgressBar } from "../../static/progress-bar"

const RenderTags = tags => {
  return tags.map(tag => (
    <Styled.PostTag className="tag" key={tag.name}>
      {tag.name}
    </Styled.PostTag>
  ))
}

const PostImage = (image, title) =>
  image ? <Styled.PostImage alt={title} fluid={image.fluid} /> : null

const BlogPost = ({ data }) => {
  const { title, body, image, referenceTags } = data.contentfulBlogPost

  useEffect(() => {
    window.addEventListener("scroll", updateProgressBar)

    return () => window.removeEventListener("scroll", updateProgressBar)
  }, [])

  return (
    <Layout>
      <SEO title={title} />
      <Styled.ProgressContainer>
        <Styled.ProgressBar id={"progress-bar"} />
      </Styled.ProgressContainer>
      <Styled.Background className="blogpost">
        <Styled.PostHeader>
          <Styled.TitleContainer>
            <Styled.PostTitle>{title}</Styled.PostTitle>
          </Styled.TitleContainer>
          {/*<Styled.MidSectionContainer>*/}
          {/*  <Styled.BackButton onClick={() => window.history.back()}>*/}
          {/*    <i className="far fa-arrow-alt-left"></i>*/}
          {/*    <Styled.BackText>Powrót</Styled.BackText>*/}
          {/*  </Styled.BackButton>*/}
          <Styled.PostTags className="tags">
            {RenderTags(referenceTags)}
          </Styled.PostTags>
          {/*  <Styled.Placeholder />*/}
          {/*</Styled.MidSectionContainer>*/}
          {PostImage(image, title)}
        </Styled.PostHeader>

        <Styled.PostBody>
          <div
            className="body-text"
            style={{ width: "100%" }}
            dangerouslySetInnerHTML={{
              __html: body.childMarkdownRemark.html,
            }}
          />
        </Styled.PostBody>

        <Styled.PostFooter>
          <Styled.Button onClick={() => window.scrollTo(0, 0)}>
            <i className="far fa-arrow-alt-up"></i> Do góry
          </Styled.Button>
        </Styled.PostFooter>
      </Styled.Background>
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        fluid(maxWidth: 880) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      referenceTags {
        name
      }
    }
  }
`
