import { graphql, useStaticQuery } from "gatsby"

const useNewsletterImage = () => {
  const data = useStaticQuery(graphql`
    query newsletterImage {
      image: file(relativePath: { eq: "logo-500-200.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data.image.childImageSharp.fluid
}

export default useNewsletterImage
