import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"
import Img from "gatsby-image"

export const Link = styled(GatsbyLink)`
  text-decoration: none;
`

export const Background = styled.div`
  display: flex;
  flex-direction: column;
`
export const PostContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: all 300ms;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 15px;
  color: #1d1f38;
  margin-bottom: 1rem;
  justify-content: flex-start;

  :hover {
    background: rgba(255, 255, 255, 1);
    transform: scale(1.048);
    box-shadow: 0 0 15px 0 #e31b6d;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Image = styled(Img)`
  padding: 3px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  width: 40%;
  min-height: 240px;
  height: auto;
  object-fit: cover;
  margin-bottom: 0;
  border: 5px solid transparent;

  @media (max-width: 768px) {
    border-radius: 15px 15px 0 0;
    width: 100%;
  }
`

export const ImagePlaceholder = styled.div`
  width: 40%;
  height: 100%;
  padding: 3px;
  border-right: 0;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  background: transparent;

  @media (max-width: 768px) {
    border-radius: 15px 15px 0 0;
    width: 100%;
    height: 240px;
  }
`

export const Metadata = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px;

  @media (max-width: 768px) {
    padding: 10px 25px 20px;
    border-radius: 15px 15px 0 0;
    width: 100%;
  }
`

export const Title = styled.h4`
  margin: 0;
  align-self: flex-start;
  white-space: normal;
  word-break: break-word;
`

export const TagsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0 5px;
`

export const Tag = styled.div`
  cursor: default;
  color: #fff;
  background: #e31b6d;
  border: 2px solid #1d1f38;
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 3px;
  margin-right: 3px;
`

export const Description = styled.div`
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`
