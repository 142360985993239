import React from "react"

import * as Styled from "../styles/home.styles"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogpostsRows from "../components/blogposts-rows"
import useArchivePosts from "../hooks/useArchivePosts"

const ArchivePosts = () => {
  const posts = useArchivePosts()

  return (
    <Layout>
      <SEO title="Posts" />
      <BlogpostsRows posts={posts} />
      <Styled.RecentLinkContainer>
        <Styled.Link to="/">Wróć do aktualności</Styled.Link>
      </Styled.RecentLinkContainer>
    </Layout>
  )
}

export default ArchivePosts
