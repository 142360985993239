import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

export const Header = styled.header`
  margin-bottom: 20px;
`

export const Container = styled.div`
  margin: 0 auto;
  height: 80px;
  max-width: 960px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Logo = styled.img`
  
`

export const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Link = styled(GatsbyLink)`
  padding: 1rem;
  color: white;
  text-decoration: none;
  cursor: pointer;
  
  &:hover {
    transition: all 300ms;
    color: #E31B6D;
  }
`

export const DarkLink = styled(GatsbyLink)`
  //padding: 1rem;
  color: #1d1f38;
  text-decoration: none;
  cursor: pointer;
  
  &:hover {
    transition: all 300ms;
    color: #E31B6D;
  }
`

export const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const ExternalLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #1d1f38;
  
  &:not(:first-of-type) {
    margin-left: 10px;
  }
`

export const RoundedItem = styled.div`
  transition: all 300ms;
  cursor: pointer;
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 40px;
  
  &:hover {
    transition: all 300ms;
    color: #E31B6D;
    box-shadow: 0px 0px 15px 0px #E31B6D;
  }
`
