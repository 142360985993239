import styled from "styled-components"
import Img from "gatsby-image"

export const ProgressContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 4px;
  background: transparent;
`

export const ProgressBar = styled.div`
  height: 4px;
  background: #e31b6d;
  width: 0;
`

export const Background = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;
  border-radius: 15px;
  color: #1d1f38;
`

export const PostHeader = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PostTitle = styled.h2`
  margin-bottom: 1.25rem;
  word-break: break-word;
`

export const MidSectionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Placeholder = styled.div``

export const PostTags = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

export const PostTag = styled.div`
  cursor: default;
  color: #fff;
  background: #e31b6d;
  border: 2px solid #1d1f38;
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 3px;

  :not(:first-of-type) {
    margin-left: 3px;
  }
`

export const PostImage = styled(Img)`
  display: block;
  margin: 1rem 0 0.5rem 0;
  width: 100%;
  height: auto;
`

export const PostBody = styled.article`
  margin: 15px 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: justify;
  font-size: 16px;

  a {
    color: #1d1f38;
    text-decoration: underline;
    transition: all 300ms;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      transition: all 300ms;
      color: #e31b6d;
    }
  }
`

export const PostFooter = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const Button = styled.div`
  transition: all 300ms;
  text-align: left;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    transition: all 300ms;
    color: #e31b6d;
  }
`
export const BackButton = styled(Button)`
  width: 100px;
  align-self: center;
  justify-self: center;

  @media (max-width: 768px) {
    display: none;
  }
`

export const BackText = styled.span`
  margin-left: 5px;
  display: inline-block;

  @media (max-width: 768px) {
    display: none;
  }
`
