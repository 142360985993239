import React, { useEffect, useState } from "react"
import * as Styled from "../styles/newsletter-popup.styles"
import addToMailchimp from "gatsby-plugin-mailchimp"
import useNewsletterImage from "../hooks/useNewsletterImage"

export default () => {
  const [isVisible, setVisible] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [isEmailValid, setEmailValid] = useState(false)
  const [isError, setError] = useState(false)
  const [isSubscribed, setSubscribed] = useState(false)
  const image = useNewsletterImage()

  useEffect(() => {
    if (
      !document.cookie.split(";").filter(item => {
        return item.includes("subscribed=true")
      }).length
    ) {
      setTimeout(() => {
        setVisible(true)
      }, 5000)
    }
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)

    addToMailchimp(email).then(data => {
      if (data.result === "success") {
        document.cookie =
          "subscribed=true; Expires=Sun, 16 Mar 2025 07:28:00 GMT"
        setLoading(false)
        setSubscribed(true)

        setTimeout(() => {
          setVisible(false)
        }, 3000)
      } else if (data.result === "error") {
        setLoading(false)
        setError(true)
      }
    })
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    setEmailValid(re.test(String(event.currentTarget.value).toLowerCase()))
  }

  const onBackClicked = () => {
    setEmail("")
    setSubscribed(false)
    setError(false)
  }

  const hideWindow = () => {
    setVisible(false)
  }

  const renderLoading = () => (
    <>
      <Styled.LoadingContainer>
        <Styled.Loading />
      </Styled.LoadingContainer>
    </>
  )

  const renderForm = () => (
    <Styled.Form onSubmit={handleSubmit}>
      <Styled.Header>Chcesz być na bieżąco?</Styled.Header>
      <Styled.SecondaryHeader>Subskrybuj newsletter!</Styled.SecondaryHeader>
      <Styled.Input
        placeholder="Adres e-mail"
        name="email"
        type="text"
        onChange={handleEmailChange}
      />
      <Styled.SubmitButton disabled={!isEmailValid} type="submit">
        Subskrybuj
      </Styled.SubmitButton>
    </Styled.Form>
  )

  const renderThankYou = () => (
    <Styled.ThankYouContainer>
      <Styled.ThankYouHeader>Dzięki za subskrypcję!</Styled.ThankYouHeader>
      <Styled.ThankYouParagraph>Do zobaczenia wkrótce</Styled.ThankYouParagraph>
    </Styled.ThankYouContainer>
  )

  const renderError = () => (
    <>
      <Styled.ErrorContainer>
        <Styled.ErrorHeader>Wystąpił błąd</Styled.ErrorHeader>
        <Styled.ErrorParagraph>
          Coś poszło nie tak... Możliwe, że usługa mailingowa jest nieosiągalna,
          podano błędne dane lub Twój adres e-mail jest już na liście
          mailingowej
        </Styled.ErrorParagraph>
        <Styled.ErrorParagraph>
          Spróbuj ponownie za kilka chwil
        </Styled.ErrorParagraph>
      </Styled.ErrorContainer>
      <Styled.BackLink onClick={onBackClicked}>Powrót</Styled.BackLink>
    </>
  )

  return (
    <Styled.Container isVisible={isVisible}>
      <Styled.CloseButton onClick={hideWindow}>
        <i className="far fa-times"></i>
      </Styled.CloseButton>
      <Styled.Image fluid={image} />
      {isLoading
        ? renderLoading()
        : isError
        ? renderError()
        : isSubscribed
        ? renderThankYou()
        : renderForm()}
    </Styled.Container>
  )
}
