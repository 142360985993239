import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import * as Styled from "../styles/layout.styles"
import Header from "./header"
import "./layout.css"
import NewsletterPopup from "./newsletter-popup"
import useLayout from "../hooks/useLayout"

const Layout = ({ children }) => {
  const { image, title } = useLayout()

  return (
    <>
      <Styled.LogoContainer className="logo-container">
        <Styled.ExternalLink href="https://pawelzdralewicz.pl/" target="_blank">
          <Img fixed={image} />
        </Styled.ExternalLink>
      </Styled.LogoContainer>
      <Styled.SiteContainer>
        <Header siteTitle={title} />
        <Styled.ContentContainer>
          <Styled.Main>{children}</Styled.Main>
        </Styled.ContentContainer>
      </Styled.SiteContainer>
      <NewsletterPopup></NewsletterPopup>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
