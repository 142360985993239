import { graphql, useStaticQuery } from "gatsby"

const useLatestPosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(limit: 10, sort: { fields: order, order: DESC }) {
        edges {
          node {
            id
            title
            slug
            description {
              description
            }
            body {
              childMarkdownRemark {
                excerpt(pruneLength: 240)
              }
            }
            image {
              fluid(maxWidth: 800) {
                ...GatsbyContentfulFluid_tracedSVG
              }
            }
            referenceTags {
              name
            }
          }
        }
        totalCount
      }
    }
  `)

  return {
    posts: data.allContentfulBlogPost.edges.map(edge => edge.node),
    totalCount: data.allContentfulBlogPost.totalCount,
  }
}

export default useLatestPosts
