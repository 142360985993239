import React from "react"

import * as Styled from "../styles/home.styles"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogpostsRows from "../components/blogposts-rows"
import useLatestPosts from "../hooks/useLatestPosts"

const IndexPage = () => {
  const { posts, totalCount } = useLatestPosts()

  return (
    <Layout>
      <SEO title="Home" />
      <BlogpostsRows posts={posts} />
      {totalCount > 10 ? (
        <Styled.ArchiveLinkContainer>
          <Styled.Link to="/archive">Zobacz starsze wpisy</Styled.Link>
        </Styled.ArchiveLinkContainer>
      ) : null}
    </Layout>
  )
}

export default IndexPage
