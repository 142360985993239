import { graphql, useStaticQuery } from "gatsby"

const useLayout = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      image: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 70, width: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return {
    title: data.site.siteMetadata.title,
    image: data.image.childImageSharp.fixed,
  }
}

export default useLayout
