import React from "react"
import * as Styled from "../styles/404.styles"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Styled.Container to={'/'}>
        <Styled.ErrorCode>404</Styled.ErrorCode>
        <Styled.Icon className="fal fa-grin-beam-sweat"></Styled.Icon>
        <Styled.ErrorMessage>Najprawdopodobniej masz niepoprawny link albo po prostu coś popsułem...</Styled.ErrorMessage>
        <Styled.Guide>(kliknij w kafelek żeby wrócić na stronę główną)</Styled.Guide>
    </Styled.Container>
  </Layout>
)

export default NotFoundPage
