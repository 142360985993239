import styled from "styled-components"
import Img from "gatsby-image"

export const Container = styled.div`
  font-family: "Raleway", serif;
  height: 410px;
  min-width: 250px;
  width: 100%;
  max-width: 400px;
  margin: 1rem;
  z-index: 5;
  transform: translateX(${props => (props.isVisible ? "0" : "500px")});
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  transition: all 500ms;
  background: #dadbdc;
  border-radius: 15px;
  color: #1d1f38;

  @media (max-width: 420px) {
    max-width: calc(100% - 2em);
  }
`

export const CloseButton = styled.button`
  z-index: 15;
  color: #fff;
  border: 0;
  background: none;
  transition: 300ms all;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  :hover {
    color: #e31b6d;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`
export const Image = styled(Img)`
  width: 100%;
  min-height: 155px;
  height: 155px;
  max-height: 155px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`
export const Header = styled.h4`
  text-align: center;
  margin: 1rem 0;

  @media (max-width: 420px) {
    font-size: 14px;
  }
`
export const SecondaryHeader = styled.h3`
  text-align: center;
  margin: 0;

  @media (max-width: 420px) {
    font-size: 20px;
  }
`
export const Input = styled.input`
  text-align: center;
  width: 100%;
  padding: 0.5rem;
  margin: 1.5rem 0;
`
export const SubmitButton = styled.button`
  cursor: pointer;
  transition: 300ms all;
  padding: 0.5rem;
  color: #fff;
  font-weight: bold;
  background: ${props => (props.disabled ? "gray" : "#1d1f38")};
  border: 0;
  border-radius: 15px;

  :hover {
    background: ${props => (props.disabled ? "gray" : "#e31b6d")};
    transform: scale(1.048);
  }
`

export const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Loading = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #e3196b64;
  border-radius: 50%;
  border-top-color: #e3196b;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`

export const ErrorContainer = styled.div``
export const ErrorHeader = styled.h4`
  text-align: center;
  margin: 1rem 0;
`
export const ErrorParagraph = styled.p`
  text-align: center;
  margin: 1rem 0 0;
`

export const BackLink = styled.p`
  cursor: pointer;
  transition: 300ms all;
  position: absolute;
  margin: 0;
  bottom: 20px;
  right: 30px;
  font-weight: bold;

  :hover {
    color: #e31b6d;
  }
`

export const ThankYouContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const ThankYouHeader = styled.h4`
  text-align: center;
`
export const ThankYouParagraph = styled.h3`
  text-align: center;
`
