import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

export const Container = styled(GatsbyLink)`
  color: #1d1f38;
  transition: 300ms all;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.85);
  padding: 25px;
  border-radius: 15px;
  width: 100%;
  text-decoration: none;

  :hover {
    background: rgba(255, 255, 255, 1);
    transform: scale(1.048);
    box-shadow: 0px 0px 15px 0px #e31b6d;

    i {
      transform: scale(1.1);
    }
  }
`

export const Icon = styled.i`
  transition: 300ms all;
  font-size: 160px;
  margin: 2rem 0;
`

export const ErrorCode = styled.p`
  font-size: 40px;
  font-weight: bold;
  margin: 0;
`

export const ErrorMessage = styled.p`
  text-align: center;
  font-size: 18px;
  margin: 0;
`

export const Guide = styled.p`
  text-align: center;
  font-size: 12px;
  margin: 0;
`
