import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

export const Link = styled(GatsbyLink)`
  transition: all 300ms;
  color: #fff;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: #e31b6d;
  }
`

export const ArchiveLinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const RecentLinkContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
