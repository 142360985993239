import React from "react"
import PropTypes from "prop-types"
import * as Styled from "../styles/header.styles"

const Header = () => (
  <Styled.Header>
    <Styled.Container>
      <Styled.NavigationContainer>
        <Styled.DarkLink to="/">
          <Styled.RoundedItem>
          <i className="far fa-home-lg-alt"></i>
          </Styled.RoundedItem>
        </Styled.DarkLink>
        {/*<Styled.Link to="/blogposts/">Blog</Styled.Link>*/}
      </Styled.NavigationContainer>
      <Styled.SocialMediaContainer>
        <Styled.ExternalLink
          href="https://www.linkedin.com/in/pawe%C5%82-zdralewicz-ab8709124/"
          target="_blank"
        >
          <Styled.RoundedItem>
            <i className="fab fa-linkedin-in"></i>
          </Styled.RoundedItem>
        </Styled.ExternalLink>

        <Styled.ExternalLink
          href="https://github.com/pzdralewicz/"
          target="_blank"
        >
          <Styled.RoundedItem>
            <i className="fab fa-github"></i>
          </Styled.RoundedItem>
        </Styled.ExternalLink>

        <Styled.ExternalLink
          href="https://www.pawelzdralewicz.pl/"
          target="_blank"
        >
          <Styled.RoundedItem>
            <i className="far fa-globe"></i>
          </Styled.RoundedItem>
        </Styled.ExternalLink>
      </Styled.SocialMediaContainer>
    </Styled.Container>
  </Styled.Header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
